<div [ngClass]="responsiveService.getBigSmall()">
    <div class="whoo-left-right context-bar-container">
        <div class="left context-bar-item">
            <button mat-button class="navigation-button" (click)="navigateBack()">
                <mat-icon class="icon">navigate_before</mat-icon>
                <span
                    *ngIf="routeContextService.title && responsiveService.isScreenBig()">{{trans.translate('actionLabels.back')}}</span>
            </button>

        </div>
        <div *ngIf="routeContextService.title" class="middle context-bar-item">
            <span>{{routeContextService.title}}</span>
        </div>
        <span class="right context-bar-item" [bdcWalkTriggerFor]="hintActionBar">

            <!--BIG SCREEN-->
            <span *ngIf=" !responsiveService.isScreenSmall()" class="button-bar">

                <!--shareEvent & shareResultMatrix-->
                <ng-container *ngIf="routeContextService.id ==='pollResponses'">
                    <button mat-icon-button color="primary" [matMenuTriggerFor]="shareMenu"
                        matTooltip="{{trans.translate('actionLabels.share' )}}">
                        <mat-icon class="icon">ios_share</mat-icon>
                    </button>
                    <mat-menu #shareMenu="matMenu">
                        <span>
                            <!--shareEvent-->
                            <button mat-menu-item (click)="sharePoll()">
                                <span>{{trans.translate('actionLabels.shareEvent' )}}</span>
                            </button>
                            <!--shareResultMatrix-->
                            <button mat-menu-item (click)="shareResult()">
                                <span>{{trans.translate('actionLabels.shareResultMatrix' )}}</span>
                            </button>
                        </span>
                    </mat-menu>
                </ng-container>

                <!--shareEvent-->
                <ng-container *ngIf="routeContextService.id !=='pollResponses'">
                    <button mat-icon-button color="primary" (click)="sharePoll()"
                        matTooltip="{{trans.translate('actionLabels.share' )}}">
                        <mat-icon class="icon">ios_share</mat-icon>
                    </button>
                </ng-container>


                <!--navigate editPoll-->
                <button *ngIf="routeContextService.id !=='editPoll' && routeContextService.id !=='votePreview' "
                    mat-icon-button (click)="editPoll()" matTooltip="{{trans.translate('actionLabels.edit' )}}"
                    aria-label="Edit">
                    <mat-icon class="icon">edit_outline</mat-icon>
                </button>

                <!--open sidebar to edit-->
                <button *ngIf="routeContextService.id ==='votePreview'  || routeContextService.id ==='editPoll'"
                    mat-icon-button color="primary" matTooltip="{{trans.translate('actionLabels.edit' )}}"
                    aria-label="Edit" (click)="contextActionBarService.handleClickEdit()">
                    <mat-icon class="icon">edit</mat-icon>
                </button>

                <!--add option-->
                <add-option-button *ngIf="routeContextService.id ==='votePreview'" appearance="icon" color="primary"
                    (onAddOption)="contextActionBarService.handleClickAddOption($event)"></add-option-button>

                <!--delete-->
                <button mat-icon-button color="primary" (click)="deletePoll()"
                    matTooltip="{{trans.translate('actionLabels.delete' )}}">
                    <mat-icon class="icon">delete_outline</mat-icon>
                </button>

                <!--personalAccess-->
                <button mat-icon-button *ngIf="authService.isLoggedIn  && authService.isAnonymous" color="primary"
                    (click)="getAccessDialog()" matTooltip="{{trans.translate('actionLabels.personalAccess' )}}">
                    <mat-icon class="icon">key</mat-icon>
                </button>

                <!--downloadCalendarEntries-->
                <button mat-icon-button color="primary" (click)="handleClickCalendar()"
                    matTooltip="{{trans.translate('actionLabels.downloadCalendarEntries' )}}">
                    <mat-icon class="icon">download</mat-icon>
                </button>

                <!--pollResponses-->
                <button *ngIf="routeContextService.id !=='pollResponses'" mat-icon-button color="primary"
                    (click)="openPollOverview()" matTooltip="{{trans.translate('actionLabels.dasboard' )}}">
                    <mat-icon class="icon">bar_chart_4_bars</mat-icon>
                </button>

                <!--preview-->
                <button *ngIf="routeContextService.id !=='votePreview'" mat-icon-button color="primary"
                    (click)="openVotePage()" matTooltip="{{trans.translate('actionLabels.preview' )}}">
                    <mat-icon class="icon">preview</mat-icon>
                </button>

                <!--home-->
                <button mat-icon-button color="primary" (click)="navigationService.navigateMyPolls()"
                    matTooltip="{{trans.translate('actionLabels.home')}}" aria-label=" Home">
                    <mat-icon class="icon">home</mat-icon>
                </button>
            </span>



            <!--SMALL SCREEN-->
            <ng-container *ngIf="responsiveService.isScreenSmall()">
                <!--extra share button in vote preview to make it more present-->
                <button *ngIf="routeContextService.id ==='votePreview'  || routeContextService.id ==='editPoll'"
                    mat-icon-button color="primary" (click)="sharePoll()">
                    <mat-icon color="primary">ios_share</mat-icon>
                </button>

                <!--menu-->
                <span class="menu-button">
                    <button mat-icon-button color="primary" [matMenuTriggerFor]="menu">
                        <mat-icon color="primary">more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">

                        <!--share without shareResultMatrix-->
                        <ng-container *ngIf="routeContextService.id !=='pollResponses'">
                            <button mat-menu-item (click)="sharePoll()">
                                <mat-icon>ios_share</mat-icon>
                                <span>{{trans.translate('actionLabels.shareEvent' )}}</span>
                            </button>
                        </ng-container>

                        <!--share with shareResultMatrix-->
                        <ng-container *ngIf="routeContextService.id ==='pollResponses'">
                            <button mat-menu-item [matMenuTriggerFor]="subMenuShare">
                                <mat-icon>ios_share</mat-icon>
                                <span>{{trans.translate('actionLabels.share' )}}</span>
                            </button>

                            <!--add sub-menu-->
                            <mat-menu #subMenuShare="matMenu">
                                <!--sharePoll-->
                                <button mat-menu-item (click)="sharePoll()">
                                    <mat-icon>ios_share</mat-icon>
                                    <span>{{trans.translate('actionLabels.shareEvent' )}}</span>
                                </button>
                                <!--shareResultMatrix-->

                                <button mat-menu-item (click)="shareResult()">
                                    <mat-icon>ios_share</mat-icon>
                                    <span>{{trans.translate('actionLabels.shareResultMatrix')}}</span>
                                </button>
                            </mat-menu>

                        </ng-container>

                        <!-- Add with sub menu -->
                        <button mat-menu-item [matMenuTriggerFor]="subMenu">
                            <mat-icon>add</mat-icon>
                            <span>{{'options.buttonAdd' | transTemplate | async}}</span>
                        </button>
                        <!--add sub-menu-->
                        <mat-menu #subMenu="matMenu">
                            <button *ngFor="let configKey of templateService.getOptionConfigKeys(false)" mat-menu-item
                                (click)="contextActionBarService.handleClickAddOption(configKey)">
                                <span>{{templateService.getOptionConfigKeyLabel(configKey)}}</span>
                            </button>
                        </mat-menu>

                        <!--personalAccess-->
                        <button mat-menu-item *ngIf="authService.isLoggedIn  && authService.isAnonymous"
                            (click)="getAccessDialog()">
                            <mat-icon>login</mat-icon>
                            <span>{{trans.translate('actionLabels.personalAccess')}}</span>
                        </button>

                        <!--navigate editPoll-->
                        <button mat-menu-item (click)="editPoll()"
                            *ngIf="routeContextService.id !=='editPoll'  && routeContextService.id !=='votePreview'">
                            <mat-icon>edit</mat-icon>
                            <span>{{trans.translate('actionLabels.edit')}}</span>
                        </button>

                        <!--open sideNav to edit-->
                        <button mat-menu-item *ngIf="routeContextService.id ==='votePreview'"
                            (click)="contextActionBarService.handleClickEdit()">
                            <mat-icon>edit</mat-icon>
                            <span>{{trans.translate('actionLabels.edit')}}</span>
                        </button>

                        <!--delete-->
                        <button mat-menu-item (click)="deletePoll()">
                            <mat-icon>delete</mat-icon>
                            <span>{{trans.translate('actionLabels.delete')}}</span>
                        </button>

                        <!--downloadCalendarEntries-->
                        <button mat-menu-item (click)="handleClickCalendar()">
                            <mat-icon>download</mat-icon>
                            <span>{{trans.translate('actionLabels.downloadCalendarEntries')}}</span>
                        </button>

                        <!--dashboard-->
                        <button mat-menu-item (click)="openPollOverview()"
                            *ngIf="routeContextService.id !=='pollResponses'">
                            <mat-icon>bar_chart_4_bars</mat-icon>
                            <span>{{trans.translate('actionLabels.dasboard')}}</span>
                        </button>

                        <!--preview-->
                        <button mat-menu-item (click)="openVotePage()" *ngIf="routeContextService.id !=='votePreview'">
                            <mat-icon>preview</mat-icon>
                            <span>{{trans.translate('actionLabels.preview')}}</span>
                        </button>

                        <!--home-->
                        <button mat-menu-item (click)="navigationService.navigateMyPolls()">
                            <mat-icon>home</mat-icon>
                            <span>{{trans.translate('actionLabels.home')}}</span>
                        </button>
                    </mat-menu>
                </span>
            </ng-container>

        </span>
    </div>
</div>

<bdc-walk-popup #hintActionBar [xPosition]="responsiveService.isScreenSmall()?'before':'after'"
    [offsetX]="responsiveService.isScreenSmall()?-24:50" [showCloseButton]="true" [showButton]="true"
    buttonText="{{'vote.hintStandardButton' | transTemplate | async}}"
    class="whoo-bdc-walk-popup whoo-bdc-walk-popup-mobile-right" [alignCenter]="false" name="hintActionBar"
    header=" {{'previewHint.hintActionBar' | transTemplate | async}}" [arrow]="true">
    {{'previewHint.hintActionBarDetails' | transTemplate | async}}
</bdc-walk-popup>