import { ChoiceEnum } from "../vote/choice.class";
import { OptionTypeEnum } from "./option-type.class";
export type SecondaryAnswerType = 'slider' | 'text' | 'textBox' | 'textAndNumber' | 'number' | 'checkboxes' | 'radio' | 'dropdown'

export type OptionConfigData = {
    questionType: OptionTypeEnum
    pimaryAnswer: 'none' | 'yes' | 'willJoin';
    showBasedOnPrimaryAnswer?: ChoiceEnum[],/** list all options for which  the secondary answer showed be displayed */
    secondaryAnswer?: SecondaryAnswerType
    multipleSecondaryAnswers?: boolean,
    showMaxParticipants?: boolean;
    icon?: string;
    isParent?: boolean;
    allowToAddOptions?: boolean /** does it show in the list of option that a particpant can add to a polll */
};

export const optionConfigMap = new Map<string, OptionConfigData>([
    //Date with checkbox
    ['date', {// this is the fall back for old text options without an option-config
        questionType: OptionTypeEnum.date,
        showMaxParticipants: true,
        pimaryAnswer: 'yes',
        icon: 'check_box',
        allowToAddOptions: true,
    },],

    // Date with registration
    ['date_willJoin', {
        questionType: OptionTypeEnum.date,
        showMaxParticipants: true,
        pimaryAnswer: 'willJoin',
        showBasedOnPrimaryAnswer: [ChoiceEnum.yes],
        icon: 'groups',
        isParent: true,
        allowToAddOptions: false,

    },],


    //Divider
    ['divider', {
        questionType: OptionTypeEnum.text,
        pimaryAnswer: 'none',
        showMaxParticipants: false,
        icon: 'horizontal_rule',
        isParent: true,
        allowToAddOptions: false,

    },],

    //Question with checkbox
    ['text', {// this is the fall back for old date options without an option-config
        questionType: OptionTypeEnum.text,
        showMaxParticipants: true,
        pimaryAnswer: 'yes',
        icon: 'check_box',
        allowToAddOptions: true,

    },],

    //Text and Number
    ['textAndNumber_textAndNumber', {
        questionType: OptionTypeEnum.textAndNumber,
        showMaxParticipants: false,
        pimaryAnswer: 'none',
        secondaryAnswer: 'textAndNumber',
        multipleSecondaryAnswers: true,
        icon: 'format_list_numbered',
        allowToAddOptions: true,
    },],

    ['text_textAndNumber', {
        questionType: OptionTypeEnum.text,
        showMaxParticipants: false,
        pimaryAnswer: 'none',
        secondaryAnswer: 'textAndNumber',
        multipleSecondaryAnswers: true,
        icon: 'format_list_numbered',
        allowToAddOptions: true,
    },],
    //Text and text
    ['text_text', {
        questionType: OptionTypeEnum.text,
        showMaxParticipants: false,
        pimaryAnswer: 'none',
        secondaryAnswer: 'textBox',
        multipleSecondaryAnswers: false,
        icon: 'list',
        allowToAddOptions: true,
    },],

    // Text and slider / linear scale
    ['text_slider', {
        questionType: OptionTypeEnum.text,
        showMaxParticipants: false,
        pimaryAnswer: 'none',
        secondaryAnswer: 'slider',
        //  icon: 'sliders',
        icon: 'linear_scale',
        allowToAddOptions: false,

    },],


    // 'checkboxes'
    ['checkboxes', {
        questionType: OptionTypeEnum.text,
        showMaxParticipants: false,
        pimaryAnswer: 'none',
        secondaryAnswer: 'checkboxes',
        icon: 'checklist',
        allowToAddOptions: false,
    },],
    // 'radio',
    ['radio', {
        questionType: OptionTypeEnum.text,
        showMaxParticipants: false,
        pimaryAnswer: 'none',
        secondaryAnswer: 'radio',
        icon: 'radio_button_checked',
        allowToAddOptions: false,
    },],

    // 'drowdown',
    ['dropdown', {
        questionType: OptionTypeEnum.text,
        showMaxParticipants: false,
        pimaryAnswer: 'none',
        secondaryAnswer: 'dropdown',
        icon: 'arrow_drop_down_circle',
        allowToAddOptions: false,
    },],


]);
